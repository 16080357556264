.contribute a {
    @apply underline text-blue-600 hover:text-blue-800 visited:text-purple-600;
}

.contribute h1,h2 {
    font-weight: bold;
    font-size: 1.6rem;
    margin-top: 1rem;
}

.contribute h1:first-of-type {
    margin-top: 0;
}

.contribute h3 {
    font-weight: bold;
    font-size: 1rem;
}
